/* ListItem.css */
.list-item {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  padding: 2vw;
  padding-left: 6vw;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none; /* no underline */
}

.list-item:nth-child(even) {
  background-color: #5b6670;
}

.list-item:nth-child(even):hover {
  background-color: #111;
  color: #f8f8f8;
}

.list-item:nth-child(odd) {
  background-color: #4d5761;
}

.list-item:nth-child(odd):hover {
  background-color: #111;
  color: #f8f8f8;
}

.list-icon {
  float: left;
  border: 1px solid #999;
  border-radius: 5px;
  box-shadow: 0px 1px 1px #000;
  max-width: 50px;
}

.list-subject {
  padding-left: 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: block;
  /* position: relative; */
  text-shadow: 0px 1px 1px #111;
  text-decoration: none;
}

.list-description {
  padding-left: 20px;
  color: #999;
  display: block;
  font-size: 14px;
  text-shadow: 0px 1px 1px #111;
  text-decoration: none;
}
