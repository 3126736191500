/* Body.css */
.app-container {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.settings-container {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.pincode-container {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.pincode-container h2 {
  text-align: center;
}

.list-Container {
  flex: 1;
}

.content {
  flex: 1;
}

/* Modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 500px;
}

.back-icon {
  height: 24px;
  width: 24px;
}

.content {
  padding: 40px;
  background: #fff;
}

.subscribe {
  margin: 20px auto;
}

.content h2,
.content h3 {
  margin-bottom: 20px;
}
