.pin-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
}

.square-box {
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 10vw;
  border: 2px solid #0d5ad6;
  border-radius: 10px;
  font-size: 6vw;
  margin: 2vw;
}

.square-box-number {
  font-size: 100%;
  font-weight: bold;
}
