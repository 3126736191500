/* Footer.css */
.footer {
  background-color: #f8f8f8;
  color: #fff;
  padding: 2vw;
  padding-left: 6vw;
  margin: 20px;
  text-align: left;
  font-size: 14px;
}

.footer-copyright {
  color: #050505;
  font-size: 14px;
  text-decoration: none;
  /* no underline */
}

.footer-aaa {
  color: #00adbb;
  font-size: 14px;
  text-decoration: none;
  /* no underline */
}
